<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="도급업체 평가 기본정보" class="cardClassDetailForm" topClass="topcolor-blue">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && data.evaluationData.evaluationCompleteFlag != 'Y' && popupParam.approvalDocType == 'TODO'"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="기본정보/평가자 저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :required="true"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.evaluationData.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-vendor
                :editable="editable"
                :required="true"
                label="평가업체"
                name="vendorCd"
                v-model="data.evaluationData.vendorCd">
              </c-vendor>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-select
                :required="true" 
                :editable="editable"
                codeGroupCd="VENDOR_EVALUATION_KIND_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="vendorEvaluationKindCd"
                label="평가구분"
                v-model="data.evaluationData.vendorEvaluationKindCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :required="true"
                :editable="editable"
                :range="true"
                label="평가기간"
                name="period"
                v-model="data.evaluationData.evaluationPeriod">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :required="true" 
                :editable="editable"
                type="year"
                label="평가년도"
                name="evaluationYear"
                v-model="data.evaluationData.evaluationYear"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text 
                :editable="editable"
                label="평가결과 종합"
                name="remarks"
                v-model="data.evaluationData.remarks">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-show="data.evaluationData.evaluationGradeName">
              <q-chip outline square>
                  <q-avatar icon="bookmark" color="orange" text-color="white" /> 
                  {{"(" + data.evaluationData.totalScore + ") " + data.evaluationData.score + " / " + data.evaluationData.evaluationGradeName}}
                  <q-tooltip>{{ "(배점) 평점 / 등급 " }}</q-tooltip>
              </q-chip>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top:-10px">
      <c-card title="안전에 관한 사항" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-12" style="margin-top:-15px">
            <c-table
              ref="table2"
              title="평가자"
              :columns="grid2.columns"
              :data="grid2.data"
              :gridHeight="grid2.height"
              cardClass="topcolor-blue"
              :columnSetting="false"
              :usePaging="false"
              :isFullScreen="false"
              :isExcelDown="false"
              :hideBottom="true"
              :filtering="false"
              :isTop="false"
            >
            </c-table>
          </div>
          <div class="col-12" style="margin-top:-10px" v-show="popupParam.mdmVendorEvaluationId">
            <c-table
              ref="table1"
              title="평가 항목"
              :columns="grid1.columns"
              :data="grid1.data"
              :gridHeight="grid1.height"
              cardClass="topcolor-blue"
              :columnSetting="false"
              :usePaging="false"
              :isFullScreen="false"
              :isExcelDown="false"
              :hideBottom="true"
              :filtering="false"
            >
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn v-if="editable && data.evaluationData.evaluationCompleteFlag != 'Y' && popupParam.approvalDocType == 'TODO'" label="평가 저장" icon="save" @btnClicked="saveEvalItem1" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top:-10px">
      <c-card title="작업에 관한 사항" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-12" style="margin-top:-15px">
            <c-table
              ref="table4"
              title="평가자"
              :columns="grid4.columns"
              :data="grid4.data"
              :gridHeight="grid4.height"
              cardClass="topcolor-blue"
              :columnSetting="false"
              :usePaging="false"
              :isFullScreen="false"
              :isExcelDown="false"
              :hideBottom="true"
              :filtering="false"
              :isTop="false"
            >
            </c-table>
          </div>
          <div class="col-12" style="margin-top:-10px" v-show="popupParam.mdmVendorEvaluationId">
            <c-table
              ref="table3"
              title="평가 항목"
              :columns="grid3.columns"
              :data="grid3.data"
              :gridHeight="grid3.height"
              cardClass="topcolor-blue"
              :columnSetting="false"
              :usePaging="false"
              :isFullScreen="false"
              :isExcelDown="false"
              :hideBottom="true"
              :filtering="false"
            >
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn v-if="editable && data.evaluationData.evaluationCompleteFlag != 'Y' && popupParam.approvalDocType == 'TODO'" label="평가 저장" icon="save" @btnClicked="saveEvalItem2" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-assess-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmVendorEvaluationId: '',
        isApprContent: true,
        approvalDocType: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      saveable: false,
      deleteable: false,
      updateMode: false,
      isSave: false,
      isApproval: false,
      saveType: 'POST',
      detailUrl: '',
      saveUrl: transactionConfig.mdm.cim.vendor.result.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      userListUrl: '',
      userSaveUrl: '',
      itemListUrl: '',
      itemSaveUrl: '',
      itemDeleteUrl: '',
      gradeItems: [],
      data: {
        evaluationData: {
          mdmVendorEvaluationId: '',  // 업체평가 일련번호
          vendorCd: null,  // 업체코드
          plantCd: null,  // 사업장코드
          vendorEvaluationKindCd: null,  // 도급업체 평가구분
          evaluationStartDate: '',  // 평가시작일
          evaluationEndDate: '',  // 평가종료일
          evaluationPeriod: [],
          evaluationYear: '',  // 평가 년도
          score: null,  // 평점
          totalScore: null,  // 총점
          evaluationGradeCd: null,  // 평가등급
          evaluationGradeName: '',
          remarks: '',  // 비고
          evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
          approvalStatusCd: '', // 결재관련 결재상태
          sysApprovalRequestId: '', // 결재관련 결재요청코드
        },
        evaluationUsers: []
      },
      grid1: {
        columns: [
          {
            name: "itemNm",
            field: "itemNm",
            label: "평가항목",
            align: "left",
            style: 'width:250px', 
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:60px', 
            align: "center",
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "평점",
            style: 'width:60px', 
            type: "number",
            align: "center",
            setHeader: true,
            sortable: false,
          },
          {
            name: "remarks",
            field: "remarks",
            label: "비고",
            type: "text",
            align: "left",
            sortable: false,
          },
        ],
        data: [],
        height: '440px'
      },
      grid2: {
        columns: [
          {
            name: 'evaluationUserTitle',
            field: 'evaluationUserTitle',
            label: '평가자구분명',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'evaluationUserName',
            field: 'evaluationUserName',
            label: '평가자',
            align: 'center',
            type: 'user',
            userId: 'evaluationUserId',
            sortable: false,
          },
        ],
        data: [],
        height: '140px'
      },
      grid3: {
        columns: [
          {
            name: "itemNm",
            field: "itemNm",
            label: "평가항목",
            align: "left",
            style: 'width:250px', 
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:60px', 
            align: "center",
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "평점",
            style: 'width:60px', 
            type: "number",
            align: "center",
            setHeader: true,
            sortable: false,
          },
          {
            name: "remarks",
            field: "remarks",
            label: "비고",
            type: "text",
            align: "left",
            sortable: false,
          },
        ],
        data: [],
        height: '440px'
      },
      grid4: {
        columns: [
          {
            name: 'evaluationUserTitle',
            field: 'evaluationUserTitle',
            label: '평가자구분명',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'evaluationUserName',
            field: 'evaluationUserName',
            label: '평가자',
            align: 'center',
            type: 'user',
            userId: 'evaluationUserId',
            sortable: false,
          },
        ],
        data: [],
        height: '140px'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.evaluationData.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.evaluationData.approvalStatusCd, 
        apprEditable: this.data.evaluationData.evaluationCompleteFlag === 'N', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000002', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          mdmVendorEvaluationId: this.data.evaluationData.mdmVendorEvaluationId,
          isApprContent: true
        },
        approvalRequestName: '도급업체 평가 ['+this.data.evaluationData.vendorName+']', // 결재요청명 (문서 title)
        approvalConnId: this.data.evaluationData.mdmVendorEvaluationId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.detailUrl = selectConfig.mdm.cim.vendor.evaluation.result.get.url;
      this.userListUrl = selectConfig.mdm.cim.vendor.evaluation.result.user.url;
      this.itemListUrl = selectConfig.mdm.cim.vendor.evaluation.result.item.url;

      this.insertUrl = transactionConfig.mdm.cim.vendor.result.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.result.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.result.delete.url;
      this.userSaveUrl = transactionConfig.mdm.cim.vendor.result.user.save.url;
      this.itemSaveUrl = transactionConfig.mdm.cim.vendor.result.item.save.url;
      this.itemDeleteUrl = transactionConfig.mdm.cim.vendor.result.item.delete.url;

      this.$comm.getComboItems('EVALUATION_GRADE_CD').then(_result2 => {
        this.gradeItems = _result2;
      });

      this.getEvalInfo();
      this.getEvalUsers();
      this.getResultItem();
    },
    getApprAfter() {
      this.getEvalInfo();
      this.getEvalUsers();
    },
    getEvalInfo() {
      if (this.popupParam.mdmVendorEvaluationId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmVendorEvaluationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.evaluationData = _result.data;
          this.updateMode = true;
          this.saveType = 'PUT'
        },);
      }
    },
    getEvalUsers() {
      if (this.popupParam.mdmVendorEvaluationId) {
        this.$http.url = this.userListUrl;
        this.$http.param = {mdmVendorEvaluationId: this.popupParam.mdmVendorEvaluationId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = this.$_.filter(_result.data, {vendorEvaluationTypeCd : 'VETC000001' });
          this.grid4.data = this.$_.filter(_result.data, {vendorEvaluationTypeCd : 'VETC000002' });
        },);
      } else {
        this.grid2.data = [
          {
            evaluationUserTitle: '안전관리자',
            vendorEvaluationTypeCd: 'VETC000001', // 안전에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 1,
          },
          {
            evaluationUserTitle: '안전팀장',
            vendorEvaluationTypeCd: 'VETC000001', // 안전에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 2,
          },
          {
            evaluationUserTitle: '안전팀 담당임원',
            vendorEvaluationTypeCd: 'VETC000001', // 안전에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 3,
          },
        ]
        this.grid4.data = [
          {
            evaluationUserTitle: '작업관련평가자',
            vendorEvaluationTypeCd: 'VETC000002', // 작업에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 1,
          },
          {
            evaluationUserTitle: '평가자팀장',
            vendorEvaluationTypeCd: 'VETC000002', // 작업에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 2,
          },
          {
            evaluationUserTitle: '평가자팀 담당임원',
            vendorEvaluationTypeCd: 'VETC000002', // 작업에 관한 사항
            evaluationUserName: '',
            evaluationUserId: '',
            sortOrder: 3,
          },
        ]
      }
    },
    getResultItem() {
      if (this.popupParam.mdmVendorEvaluationId) {
        this.$http.url = this.itemListUrl;
        this.$http.param = {mdmVendorEvaluationId: this.popupParam.mdmVendorEvaluationId, plantCd: this.data.evaluationData.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid1.data = this.$_.filter(_result.data, {vendorEvaluationTypeCd : 'VETC000001' });
          this.grid3.data = this.$_.filter(_result.data, {vendorEvaluationTypeCd : 'VETC000002' });
        },);
      }
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.evaluationUsers = this.grid2.data.concat(this.grid4.data);
              this.data.evaluationData.regUserId = this.$store.getters.user.userId
              this.data.evaluationData.chgUserId = this.$store.getters.user.userId
              this.data.evaluationData.evaluationStartDate = this.data.evaluationData.evaluationPeriod[0]
              this.data.evaluationData.evaluationEndDate = this.data.evaluationData.evaluationPeriod[1]
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.evaluationUsers = this.grid2.data.concat(this.grid4.data);
          this.data.evaluationData.regUserId = this.$store.getters.user.userId
          this.data.evaluationData.chgUserId = this.$store.getters.user.userId
          this.data.evaluationData.evaluationStartDate = this.data.evaluationData.evaluationPeriod[0]
          this.data.evaluationData.evaluationEndDate = this.data.evaluationData.evaluationPeriod[1]
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveCallback(_result) {
      if (this.saveType == 'POST') {
        this.$set(this.popupParam, 'mdmVendorEvaluationId', _result.data.evaluationData.mdmVendorEvaluationId)
      }
      this.getEvalInfo();
      this.getEvalUsers();
      this.getResultItem();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    saveEvalItem(_data) {
      let saveData = _data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        let _totaldata = this.grid1.data.concat(this.grid3.data);
        let isValidOver = false;
        this.$_.forEach(_totaldata, item => {
          if (Number(item.scoring) < Number(item.evaluationScore)) {
            isValidOver = true;
          }
        });
        if (isValidOver) {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '배점보다 높은 평점을 입력하셨습니다.\n\r다시한번 확인하시기 바랍니다.', /* 배점보다 높은 평점을 입력하셨습니다. 다시한번 확인하시기 바랍니다. */
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              let totaldata = this.grid1.data.concat(this.grid3.data);
              let _totalscore = 0;
              let _score = 0;
              let _grade = 0;
              this.$_.forEach(totaldata, item => {
                _totalscore += Number(item.scoring);
                _score += Number(item.evaluationScore);
              });
              
              this.$_.forEach(this.gradeItems, item => {
                if (_score >= Number(item.attrVal1) && _score <= Number(item.attrVal2)) {
                  _grade = item.code;
                }
              });

              this.$http.url = this.itemSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = {
                itemSaveData : saveData,
                evaluationData : {
                  totalScore: _totalscore,
                  score: _score,
                  evaluationGradeCd: _grade,
                  mdmVendorEvaluationId: this.data.evaluationData.mdmVendorEvaluationId,
                }
              };
              this.$http.request(() => {
                this.getEvalInfo();
                this.getResultItem();
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }        
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveEvalItem1() {
      this.saveEvalItem(this.grid1.data);
    },
    saveEvalItem2() {
      this.saveEvalItem(this.grid3.data);
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.mdmVendorEvaluationId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('deleteAssess');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>